$font-size: 16;

@function rem-calc($size) {
  $remSize: $size / $font-size;
  @return #{$remSize}rem;
}

html {
  font-size: $font-size+px;
  box-sizing: border-box;
}
*, *:after, *:before {
  box-sizing: inherit;
}
body {
  background: #ffffff;
  color: #000000;
  margin: 0;
  padding: 0;
  font-family: 'Roboto Condensed', sans-serif;
  height: 100vh;
  min-height: 768px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .wrapper {
    z-index: 2;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    min-height: 768px;
    width: 50%;
    background: radial-gradient(40.03% 144.64% at 2.79% 50%, #F5F5F5 0%, rgba(196, 196, 196, 0) 100%);
  }
}
footer {
  display: flex;
  font-size: rem-calc(24);
  padding: rem-calc(40) 0;
  width: 100%;
  z-index: 5;
}
main {

}
h2 {
  font-size: rem-calc(48);
  text-transform: uppercase;
}
input {
  background-color: #000000;
  padding: rem-calc(15) rem-calc(20);
  border-radius: rem-calc(10);
  border: none;
  outline: none;
  font-size: rem-calc(18);
  color: #ffffff;
  min-width: rem-calc(350);

}
textarea {
  background-color: #000000;
  padding: rem-calc(15) rem-calc(20);
  border-radius: rem-calc(10);
  border: none;
  outline: none;
  font-size: rem-calc(18);
  color: #ffffff;
  min-width: rem-calc(350);
}
a {
  color: #000000;
  text-decoration: none;
}
h1 {

}
p {
  font-size: rem-calc(18);
}
.form-group {
  position: relative;
  padding-top: 30px;
  padding-bottom: 15px;
  label {
    position: absolute;
    top:0;
    left: 0;
    font-size: rem-calc(18);
  }

  input[type="checkbox"] + label {
    position: relative;
    margin-left: rem-calc(15);
    &:before {
      content: '';
      height: rem-calc(20);
      width: rem-calc(20);
      left: rem-calc(-38);
      top: 0;
      position: absolute;
      border: 2px solid #000000;
      z-index: 1;
    }
    &:after {
      content: '';
      height: rem-calc(15);
      width: rem-calc(15);
      left: rem-calc(-36);
      top: 1px;
      position: absolute;
      z-index: 1;
      opacity: 0;
      background: url("/img/checked.svg") no-repeat center center/contain;
      transition: all 200ms ease-in-out;
    }
  }
  input[type="checkbox"] {
    min-width: auto;
    opacity: 0;
    &:checked ~ label:after {
      opacity: 1;
    }
  }
  ::-webkit-input-placeholder{
    color: #ffffff;
    font-family: 'Roboto Condensed', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: rem-calc(18);
    line-height: 100%;
  }
  :-ms-input-placeholder{
    color: #ffffff;
    font-family: 'Roboto Condensed', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: rem-calc(18);
    line-height: 100%;
  }
  ::placeholder {
    color: #ffffff;
    font-family: 'Roboto Condensed', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: rem-calc(18);
    line-height: 100%;
  }
}
.container {
  width: 83%;
  margin: 0 auto;
}
.d-flex {
  display: flex;
  &-column {
    flex-direction: column;
  }
  &-justify {
    &-center {
      justify-content: center;
    }
    &-between {
      justify-content: space-between;
    }
  }
}

.flex-50 {
  flex: 0 0 50%;
}

.modal {
  position: absolute;
}

.btn {
  z-index: 3;
  position: relative;
  font-size: rem-calc(24);
  color: #ffffff;
  background: #000000;
  border-radius: rem-calc(10);
  padding: rem-calc(10) rem-calc(60);
  text-transform: uppercase;
}

.left-side {
  display: flex;
  z-index: 3;
  justify-content: space-between;
  flex-direction: column;
  padding-bottom: rem-calc(40);
  flex: 0 0 45%;

  .logo {
    z-index: 3;
    position: relative;
    margin-top: rem-calc(130);
    transition: all 200ms ease-in-out;
    img {
      width: rem-calc(268);
      transition: all 200ms ease-in-out;
    }
    .phone {
      font-size: rem-calc(36);
      transition: all 200ms ease-in-out;
    }
  }
  .data {
    flex: 0 0 70% ;
    z-index: 3;
  }
  .menu {
    z-index: 3;
    position: relative;
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        padding: rem-calc(5) 0;
        font-size: rem-calc(36);
        text-transform: uppercase;
      }
    }
  }
  #home {
    .menu {
      margin-top: rem-calc(90);
      margin-bottom: rem-calc(90);
    }
  }
}

.right-side {
  background: url("/img/right-side-bg.jpg") no-repeat center center/contain;
  height: calc(100vh - 4rem);
  position: absolute;
  width: 60%;
  right: 0;
}
.data {
  display: flex;
  position: relative;
  transition: all 200ms ease-in-out;
}
.slide {
  transform: translate(-100vw,0);
  position: absolute;
  top: 0;
  height: 0;
  overflow: hidden;
  &.active {
    animation: slideActive 500ms ease 300ms forwards;
    height: auto;
    overflow: initial;
  }
  &.close {
    animation: slideClose 300ms ease forwards;
  }
}

body.nohome {
  .logo {
    margin-top: rem-calc(45);
    img {
      width: rem-calc(175);
    }
    .phone {
      font-size: rem-calc(24);
    }
  }
}



select ~ label {
  top: rem-calc(-5);
  font-size: rem-calc(10);
  position: absolute;
  left: rem-calc(24);
  z-index: 12;
}
select ~ .nice-select {
  position: relative;
  .list {
    position: absolute;
    background: rgba(0,0,0,1);
    z-index: 10;
    width: rem-calc(320);
    left: 10px;
    top:0;
    margin: 0;
    padding: rem-calc(40) rem-calc(24) rem-calc(10);
    border-radius: rem-calc(10);
    border: 1px solid rgba(255,255,255,0.2);
    list-style: none;
    border-top: none;
    box-shadow: 0 2px 5px rgba(0,0,0,0.6);
    transition: all 200ms ease-in-out;
    opacity: 0;
    height: auto;
    max-height: 0;
    pointer-events: none;
    overflow: hidden;
    li {
      font-size: rem-calc(18);
      margin-top: rem-calc(12);
      cursor: pointer;
      color: #ffffff;
      &.selected {
        color: #ffffff;
        font-weight: 700;
        padding-left: 10px;
        position: relative;
        &:before {
          position: absolute;
          top:0;
          content: '';
          left: -5px;
          height: 100%;
          width: rem-calc(10);
          background: url("/img/select.svg") no-repeat center center/contain;
          transition: all 200ms ease-in-out;
          transform: rotate(-90deg);
        }
      }
    }
  }
  .current {
    border: 1px solid rgba(255,255,255,0.5);
    background-color: #000000;
    border-top: none;
    border-radius: rem-calc(10);
    padding: rem-calc(13) rem-calc(20);
    position: relative;
    width: rem-calc(350);
    font-size: rem-calc(18);
    line-height: rem-calc(24);
    color: #ffffff;
    height: rem-calc(51);
    max-width: rem-calc(400);
    cursor: pointer;
    z-index: 11;
    &:after {
      content: '';
      position: absolute;
      right: rem-calc(24);
      top: 0;
      height: 100%;
      width: rem-calc(16);
      background: url("/img/select.svg") no-repeat center center/contain;
      transition: all 200ms ease-in-out;
    }
  }
  &.open {
    animation: openNiceSelectBase 100ms ease forwards;
    .list {
      animation: openNiceSelect 500ms ease forwards;
      pointer-events: all;
    }
    .current:after {
      transform: rotate(-180deg);
    }
  }
  &.close {
    animation: openNiceSelectBaseClose 500ms ease forwards;
    .list {
      animation: openNiceSelectClose 300ms ease forwards;
    }
  }
}

.mb-50 {
  margin-bottom: rem-calc(50);
}

.modal {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  z-index: 1000;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  opacity: 0;
  transition: all 200ms ease-in-out;
  .form-group {
    text-align: center;
    input,textarea {
      width: 100%;
    }
  }
  .button {
    text-align: center;
    margin-top: rem-calc(40);
  }
  .img_top {
    position: absolute;
    left: -150px;
    top: -70px;
    height: 100%;
    width: 343px;
    background: url("/img/modal_top.png") no-repeat top center/contain;
    transition: all 500ms ease-in-out;
    opacity: 0;
    transform: translate(-10000px, 0);
  }
  .wrapper {
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 20;
  }
  .window {
    position: relative;
    background: #FFFFFF;
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.15);
    border-radius: 15px;
    padding: rem-calc(90);
    transform: scale(0);
    transition: all 500ms ease-in-out;
    min-height: rem-calc(200);
    min-width: rem-calc(300);
    color: #000000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    z-index: 21;
    > .close {
      position: absolute;
      right: 24px;
      top: 24px;
      background: url('/img/close.svg') no-repeat center center/cover;
      width: 24px;
      height: 24px;
      z-index: 1;
      cursor: pointer;
    }
    .logo {
      position: absolute;
      left: rem-calc(-45);
      top: rem-calc(-35);
      background: url('/img/logo.png') no-repeat center center/cover;
      width: rem-calc(157);
      height: rem-calc(157);
      z-index: 1;
    }
    .title {
      font-size: rem-calc(36);
      font-weight: bold;
    }
    .content {
      margin: rem-calc(40) 0;
      .form-group {
        margin-bottom: rem-calc(20);
        input[type='text'] {
          border: none;
          background: #C9EAC4;
          color: #000000;
          &:not(:placeholder-shown) {
            border-top: none;

            & + label {
              top: rem-calc(-5);
              font-size: rem-calc(10);
              color: #000000;
            }
          }

          &:placeholder-shown {
            & + label {
              top: rem-calc(12);
              font-size: rem-calc(18);

            }
          }
        }
      }
    }
  }
  &.open {
    opacity: 1;
    pointer-events: all;
    .window {
      transform: scale(1);
    }
    .img_top {
      opacity: 1;
      transform: translate(0, 0);
    }
  }
}

#message {

  .form-group:last-of-type {
    margin-bottom: rem-calc(50);
  }
  .button {
    margin-top: rem-calc(50);
    .message {
      color: #CB0909;
      margin-top: rem-calc(20);
    }
  }
}
#work,
#geodesy,
#network  {
  .form-group {
    padding: 10px;
    &:first-of-type{
      margin-top: rem-calc(50);
    }
  }
  .button {
    margin-top: rem-calc(50);
    .message {
      color: #CB0909;
      margin-top: rem-calc(20);
    }
  }
}

#sand {
  .form-group {
    &:first-of-type {
      margin-top: rem-calc(50);
    }
  }
  .d-flex {
    width: rem-calc(390);
    .form-group {
      &:first-of-type {
        margin-top: 0;
      }
      flex: 0 0 50%;
      .nice-select {
        .current {
          width: rem-calc(150);
          &:first-of-type {
            margin-right: 10px;
          }
        }
        .list {
          width: rem-calc(120);
        }
      }
    }
  }
}

@import "animation";
@import "media";
