@keyframes openNiceSelectBase {
  from {
    z-index: 10;
  }
  to {
    z-index: 12;
  }
}

@keyframes openNiceSelectBaseClose {
  from {
    z-index: 12;
  }
  to {
    z-index: 10;
  }
}

@keyframes openNiceSelect {
  0% {
    max-height: 0;
    opacity: 1;
  }
  100% {
    max-height: 500px;
    opacity: 1;
  }
}

@keyframes openNiceSelectClose {
  0% {
    max-height: 500px;
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    max-height: 0;
    opacity: 0;
  }
}

@keyframes slideActive {
  0% {
    transform: translate(-100vw,0);
  }
  70% {
    transform: translate(50px, 0px);
  }
  100% {
    transform: translate(0,0);
  }
}

@keyframes slideClose {
  0% {
    transform: translate(0,0);
  }
  70% {
    transform: translate(50px, 0px);
  }
  100% {
    transform: translate(-100vw,0);
  }
}