

@media (max-width: 900px) {
  html {
    font-size: 14px;
  }
  .left-side {
    flex: 0 0 100%;
  }
  .right-side {
    background-size: cover;
    background-position: center left;
    opacity: 0.2;
  }
  .modal.open .window {
    padding: rem-calc(40);
  }
  .modal .img_top {
    width: 243px;
    left: 0;
    top: -90px;
  }
  .form-group input[type="checkbox"]+label {
    margin-left: 0;
  }
}

@media (max-width: 568px) {
  font-size: 12px;
}

@media (max-width: 320px) {
  html {
    font-size: 11px;
  }
}